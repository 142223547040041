import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import QuizIcon from "@mui/icons-material/Quiz";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress, MenuItem } from "@mui/material";
import { BASE_URL } from "../utils/constants";
import axios from "axios";
import { toast } from "react-toastify";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://homeclassgroup.com">
        Homeclass Nigeria
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function EnquiryForm() {
  const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    product: "",
    subproduct: "",
  };
  const [value, setValue] = useState(INITIAL_STATE);
  const [visaPackages, setVisaPackages] = useState([]);
  const [itfPackages, setItfPackages] = useState([]);
  const [studyPackages, setStudyPackages] = useState([]);
  const [partnerPackages, setPartnerPackage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchItfPackage = async () => {
      try {
        const { data } = await axios.get(BASE_URL + "/fetch-itf-packages");

        setItfPackages(data.itfPackages);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchVisaPackage = async () => {
      try {
        const { data } = await axios.get(BASE_URL + "/fetch-visa-packages");

        setVisaPackages(data.visaPackages);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchStudyPackage = async () => {
      try {
        const { data } = await axios.get(BASE_URL + "/fetch-study-packages");

        setStudyPackages(data.studyPackages);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    const fetchPartnersPackage = async () => {
      try {
        const { data } = await axios.get(BASE_URL + "/fetch-partner-packages");

        setPartnerPackage(data.partnerPackages);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchItfPackage();
    fetchVisaPackage();
    fetchStudyPackage();
    fetchPartnersPackage();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    await axios
      .post(BASE_URL + "/send-product-enquiry", {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phone: value.phone,
        product: value.product,
        subproduct: value.subproduct,
      })
      .then((res) => {
        if (res.data.sent) {
          setSubmitting(false);
          setValue({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            product: "",
            subproduct: "",
          });
          window.alert(res.data.sent);
          window.location.href = "https://homeclassgroup.com";
        } else {
          window.alert(res.data.notSent);
          window.location.href = "https://homeclassgroup.com";
        }
      })

      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
        setSubmitting(false);
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <QuizIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Quick Enquiry Form
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      value={value.firstName}
                      onChange={(e) => handleChange(e)}
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      value={value.lastName}
                      onChange={(e) => handleChange(e)}
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      value={value.email}
                      onChange={(e) => handleChange(e)}
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="phone"
                      label="Phone Number"
                      value={value.phone}
                      onChange={(e) => handleChange(e)}
                      type="number"
                      autoComplete="phone-number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="product"
                      label="Select Product/Services"
                      value={value.product}
                      onChange={(e) => handleChange(e)}
                      select
                    >
                      <MenuItem value="">
                        ...Select product/services to enquire...
                      </MenuItem>
                      <MenuItem value="Visa Packages">
                        Visas & Relocation
                      </MenuItem>
                      <MenuItem value="ITF">ITF/POF</MenuItem>
                      <MenuItem value="Study">Study</MenuItem>
                      <MenuItem value="BOI">Bank of Industry Funding</MenuItem>
                      <MenuItem value="Partnership">
                        Become Our Partner
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    {value.product === null ||
                    value.product === "" ||
                    value.product === "BOI" ? null : value.product === "ITF" ? (
                      <TextField
                        required
                        fullWidth
                        name="subproduct"
                        label="Select ITF Packages"
                        value={value.subproduct}
                        onChange={(e) => handleChange(e)}
                        select
                      >
                        <MenuItem value="">...Select ITF Package...</MenuItem>
                        {itfPackages.map((item) => {
                          return (
                            <MenuItem
                              value={item.productTitle}
                              key={item.productTitle}
                            >
                              {item.productTitle}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    ) : value.product === "Visa Packages" ? (
                      <TextField
                        required
                        fullWidth
                        name="subproduct"
                        label="Select Visa Package"
                        value={value.subproduct}
                        onChange={(e) => handleChange(e)}
                        select
                      >
                        <MenuItem value="">
                          ...Select a visa package...
                        </MenuItem>
                        {visaPackages.map((item) => {
                          return (
                            <MenuItem
                              value={item.productTitle}
                              key={item.productTitle}
                            >
                              {item.productTitle}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    ) : value.product === "Study" ? (
                      <TextField
                        required
                        fullWidth
                        name="subproduct"
                        label="Select Study Abroad package"
                        value={value.subproduct}
                        onChange={(e) => handleChange(e)}
                        select
                      >
                        <MenuItem value="">
                          ...Select a study abroad package...
                        </MenuItem>
                        {studyPackages.map((item) => {
                          return (
                            <MenuItem
                              value={item.productTitle}
                              key={item.productTitle}
                            >
                              {item.productTitle}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    ) : (
                      <TextField
                        required
                        fullWidth
                        name="subproduct"
                        label="Select Partnership type"
                        value={value.subproduct}
                        onChange={(e) => handleChange(e)}
                        select
                      >
                        <MenuItem value="">
                          ...Select a partnership package...
                        </MenuItem>
                        {partnerPackages.map((item) => {
                          return (
                            <MenuItem
                              value={item.productTitle}
                              key={item.productTitle}
                            >
                              {item.productTitle}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress
                      size={25}
                      style={{ margin: "0px auto" }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
